.main-screen{
  position: relative;
  background: linear-gradient(90deg, #0e0400d9, #0e0400d9), url('../img/bg/banner-bg.jpg') no-repeat center bottom;
  background-size: cover;
  &:before{
    content: "";
    position: absolute;
    background: url('../img/bg/vector-zz-gray.svg') no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 5rem 0 10rem 0;
    flex-wrap: wrap;
    @media screen and (max-width: 991.5px){
      padding: 3rem 1rem 7rem;
      flex-direction: column;
      & :first-child{
        order: 1;
      }
      & :last-child{
        order: 0;
        margin: 0 0 1rem;
      }
    }
  }
  &__form{
    &-container{
      display: flex;
      justify-content: center;
      background: url('../img/bg/banner-shape.png') no-repeat center 10%;
    }
  }
  &__text{
    &-title{
      margin: 0 0 60px;
      color: #ffffff;
      font-size: 34px;
      position: relative;
      &::after {
        position: absolute;
        left: 0;
        bottom: -30px;
        content: '';
        width: 64px;
        height: 4px;
        background: #e86338;
      }
    }
    &-desc{
      display: block;
      margin-bottom: 30px;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      color: #ffffff;
      line-height: 1.4;
    }
  }
  &__stat{
    color: $main-orange-color;
    &-container{
      display: flex;
      flex-direction: row;
    }
    &-num{
      font-size: 2rem;
      margin-bottom: 0;
    }
    @media screen and (max-width: 991.5px){
      padding: 0 1rem;
      text-align: center;
    }
  }
}
.specialization-screen{
  background: $gray-bg-color;
  padding: 1rem 0 3rem;
  &__title {
    color: $dark-orange-color;
    margin: 0 0 15px;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
  }
  &__desc{
    position: relative;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    padding-bottom: 2rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
}
.objects-screen{
  position: relative;
  padding: 9rem 0 12rem;
  background: linear-gradient(90deg, #0e0400d9, #0e0400d9), url('../img/bg/banner-bg.jpg') no-repeat center bottom;
  background-size: cover;
  &:before{
    content: "";
    position: absolute;
    background: url(../img/bg/vector-zz-gray.svg) no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    top: -1px;
    left: 0;
    transform: rotate(180deg);
  }
  &:after{
    content: "";
    position: absolute;
    background: url(../img/bg/vector-zz-white.svg) no-repeat center bottom;
    width: 100%;
    height: 12rem;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__title {
    color: white;
    margin: 0 0 15px;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
  }
  &__desc{
    position: relative;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    padding-bottom: 2rem;
    color: white;

    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }

}
.youtube-screen{
  padding: 3rem 0 9rem;
  background: white;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    background: url('../img/bg/vector-zz-gray.svg') no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__title{
    color: $dark-orange-color;
    text-align: left;
    font-weight: 500;
    font-size: $h3-font-size;
    position: relative;
    padding-bottom: 1rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &__slider{
    margin: 2rem 0;
  }
}
.partners-screen{
  padding: 3rem 0 12rem;
  background: $gray-bg-color;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    background: url('../img/bg/vector-zz-white.svg') no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__title{
    color: $dark-orange-color;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &__slider{
    margin: 2rem 0;
  }
}
.comments-screen{
  padding: 3rem 0 9rem;
  &__title{
    color: $dark-orange-color;
    text-align: left;
    font-weight: 500;
    font-size: $h3-font-size;
    position: relative;
    padding-bottom: 1rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &__container{
    margin: 1rem auto 3rem;
  }
  &__form{
    background: $gray-bg-color;
    border-radius: 4px;
    padding: 2rem;
  }
  &__label{
    font-size: .875rem;
    color: $main-orange-color;
  }
  &__input{
    font-family: "Open Sans", sans-serif;
    margin-bottom: 1rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    color: #666;
    height: 52px;
    border: 1px solid #ccc;
    padding: 0 1rem;
    border-radius: 0;
  }
  input[type="file"]{
    border: 1px solid #ccc;
    border-radius: 0;
    font-size: 12px;
  }
  textarea {
    height: calc(100% - 1.48rem);
    padding-top: 1rem;
  }
  &__attention{
    font-size: .75rem;
    text-align: center;
  }
  &__link{
    color: #000;
    text-transform: none;
    transition: color .3s ease-in-out;
    &:hover{
      text-decoration: underline;
      color: #e86338;
    }
  }
  &__btn{
    margin-bottom: .5rem;
  }
  &__container-btn{
    display: flex;
    margin: 1rem 0 0 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.about-screen{
  padding: 0 0 9rem;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background: url(../img/bg/vector-zz-gray.svg) no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__title{
    color: $dark-orange-color;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &__text{
    padding: 1rem 0;
    position: relative;
    p, li{
      font-family: "Open Sans", sans-serif;
    }
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #dcdcde;
      top: 0;
      left: 0;
    }
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: #dcdcde;
      bottom: 0;
      left: 0;
    }
  }
  &__img{
    img{
      width: 100%;
      object-fit: cover;
      box-shadow: $card-shadow;
    }
  }
  .about-tth {
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 991.5px){
      padding-bottom: 1rem;
      flex-wrap: wrap;
    }
  }
  .about-tth-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: 991.5px){
      padding: 1rem 0;
    }
  }
  .about-tth-item:nth-child(2) {
    padding: 0 30px;
    @media screen and (max-width: 991.5px){
      padding: 1rem 0;
    }
  }
  .about-tth-item img {
    flex-shrink: 0;
    display: block;
    margin-right: 20px;
  }
  .about-tth-item span {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.3;
    color: #222222;
    display: block;
  }
}

.licenses-screen{
  padding: 3rem 0 9rem;
  background: #f8f8f8;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    background: url('../img/bg/vector-zz-white.svg') no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__title {
    color: $dark-orange-color;
    margin: 0 0 15px;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
  }
  &__desc{
    color: $dark-orange-color;
    position: relative;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    padding-bottom: 2rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  .cert-list{
    .cert-container{
      display: flex;
      justify-content: center;
    }
    .slick-list{
      margin: 0 3rem;
      padding: 1rem 0 2rem;
    }
    img{
      max-height: 15rem;
      box-shadow: rgba(119, 119, 163, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
      transition: all .3s ease-in-out;
      &:hover{
        box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
        transform: translateY(-3px);
      }
    }
  }
}

.contact-screen{
  padding: 3rem 0 9rem;
  &__title{
    color: $dark-orange-color;
    margin: 0 0 15px;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
  }
  &__desc{
    color: $dark-orange-color;
    position: relative;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    padding-bottom: 2rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
}