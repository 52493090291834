.main-header {
  padding: 10px 0 0 0;
  font-size: .875rem;
  &_info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px 0;
    font-size: .75rem;
    @media screen and (max-width: 991.5px){
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &>*{
        margin-bottom: 1rem;
      }
    }
  }
  &_logo{
    display: flex;
    align-items: center;
    flex-direction: row;
    &-link{
      display: flex;
      flex-direction: row;
      width: 180px;
      text-decoration: none;
      color: $main-dark-color;
      &:hover{
        color: $main-dark-color;
      }
    }
    &-text{
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
      &_name{
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.1rem;
      }
      &_comment{
        font-size: 0.7rem;
        font-weight: 500;
        line-height: .7rem;
      }
    }
    &-img{
      width: 50px;
      object-fit: contain;
    }
  }
  &__address{
    padding: 5px 0 5px 30px;
    display: flex;
    align-items: center;
    background: url(../img/icons/address.png) no-repeat left center;
  }
  &__phone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      color: $main-dark-color;
      text-transform: none;
      font-size: 1rem;
      transition: color .3s ease-in-out;
      &:hover{
        text-decoration: underline;
        color: #e86338;
      }
    }
  }
  &__social{
    display: flex;
    align-items: center;
    &-link{
      padding: .5rem;
      opacity: .6;
      transition: opacity .3s ease-in-out;
      &:hover{
        opacity: 1;
      }
    }
    i {
      font-size: 1.5rem;
      color: $main-orange-color;
    }
  }
  &__navbar{
    background: #000;
    .navbar-toggler{
      color: white;
      border: 2px solid white;
      transition: all .3s ease-in-out;
      &:not(.collapsed){
        background: white;
        color: black;
      }
      &:focus{
        outline: none;
        box-shadow: none;
      }
    }
    .navbar{
      justify-content: flex-end;
      @media screen and (max-width: 991.5px){
        .nav-link{
          padding-right: 1rem;
        }
      }
    }
  }
  &__search{
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
    &-control{
      border-radius: 0;
    }
    &-btn{
      color: white;
      transition: color .3s ease-in-out;
      &:hover{
        color: #e86338;
      }
    }
  }
  &__navigation{
    width: 100%;
    justify-content: space-between;

  }
  &__fixed{
    .main-header__search-btn{
      opacity: .6;
      color: #e86338;
      transition: all .3s ease-in-out;
      &:hover{
        opacity: 1;
      }
    }
  }
  &__menu{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 1rem;
  }
  &__sub{
    padding: 1rem;
    font-size: .9rem;
    line-height: 1.48;
    &>ul{
      list-style: none;
      padding: 0;
    }
  }
  &__sub-item{
    a{
      text-decoration: none;
      transition: color .3s ease-in-out;
      color: $dark-orange-color;
      &:hover{
        color: $main-orange-color;
      }
    }
  }
  .nav-item{
    .main-header__navigation-link.nav-link {
      color: white;
      transition: color .3s ease-in-out;
      &:hover, &:focus{
        color: #e86338;
      }
    }
  }
  .dropdown-menu{
    box-shadow: $header-drop-shadow;
    min-width: 30rem;
    border: 0;
    @media screen and (max-width: 991.5px){
      min-width: auto;
    }
  }
  .navbar-collapse{
    justify-content: space-between;
  }
  .navbar-nav{
    flex-grow: 1;
    justify-content: space-between;
  }
  .dropdown-divider{
    background: $secondary-gray;
  }
  .nav-pills {
    padding: 0 2rem;
    @media screen and (min-width: 992px) {
      align-items: center;
      padding: 0 1rem;
      flex-wrap: nowrap;
    }
    .nav-link{
      background: transparent;
      transition: all .3s ease-in-out;
      color: $dark-orange-color;
      @media screen and (min-width: 992px) {
        padding: 0 1rem;
      }
      &.active{
        color: $main-orange-color;
        text-decoration: underline;
      }
      &.only-link{
        text-align: center;
        & .fas{
          padding-right: 4px;
        }
        &:hover{
          color: $main-orange-color;
          text-decoration: underline;
        }
      }
    }
  }
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu{ display: none; }
  .navbar .nav-item:hover .nav-link{   }
  .navbar .nav-item:hover .dropdown-menu{ display: block; }
  .navbar .nav-item .dropdown-menu{ margin-top:0; }
}

