// Colors
$main-orange-color: #e86338;
$main-dark-color: #000;
$dark-orange-color: #1c1c1c;
$orange-gradient: linear-gradient(to bottom, #ff7d60, #e86439);
$orange-gradient-active: linear-gradient(to bottom, #ff8467, #ef6b40);
$gray-bg-color: #f8f8f8;
$secondary-gray: #9b9b9b;
$secondary-orange: #fff3eb;

// Radius
$border-radius-btn: 3px;

// Sizes
$btn-font-size: .875rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.75rem;

// Shadows
$focus: 0 0 0 0.25rem rgba(255, 200, 130, 0.25);
$unactive-card: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
$card-shadow: rgba(119, 119, 163, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
$card-shadow__hover: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
$header-drop-shadow: 0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1);
