.input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #666;
  margin-bottom: 15px;
  height: 52px;
  border: 1px solid #ccc;
  padding: 0 23px 0 50px;
}
.input-name {
  background: url(../img/icons/name.png) no-repeat 19px center;
}
.input-tel {
  background: url(../img/icons/tel.png) no-repeat 19px center;
}
.input-company {
  background: url(../img/icons/company.png) no-repeat 16px center;
}
.submit {
  display: block;
  width: 100%;
  margin-top: 25px;
}