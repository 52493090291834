.slick-arrow {
  position: absolute;
  top: calc(50% - 21px);
  z-index: 2;
  cursor: pointer;
  width: 42px;
  height: 42px;
  border: 2px solid #bcbcbc;
  border-radius: 3px;
  font-size: 0;
  color: transparent;
  background: inherit;
}

.objects-slider .slick-arrow, .youtube-screen__slider .slick-arrow{
  @media screen and (max-width: 991.5px){
    top: 100%;
    margin-top: 1rem;
  }
}

.slick-arrow::before {
  position: absolute;
  top: 14px;
  left: 16px;
  content: '';
  width: 8px;
  height: 8px;
  border-left: 2px solid #bcbcbc;
  border-bottom: 2px solid #bcbcbc;
  transform: rotate(45deg);
}
.slick-arrow:hover {
  border-color: #e86338;
  background-color: #e86338;
}
.slick-arrow:hover::before {
  border-color: #fff;
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 0;
  transform: rotate(180deg);
}

.youtube-img  {
  text-align: center;
  position: relative;
}

.clients-slider{
  .slick-list{
    width: 90%;
    margin: auto;
  }
}

.client-container {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img{
    max-height: 100%;
    max-width: 90%;
  }
}