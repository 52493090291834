.contacts {
  padding: 0 0 80px;
}
.contacts-block {
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
.map {
  //position: absolute;
  //top: 0;
  //left: 0;
  //bottom: 0;
  width: 50%;
  //height: 100%;
  @media screen and (max-width: 991.5px) {
    width: 100%;
  }
}
.contacts-content {
  width: 50%;
  background: #f9f9f9;
  padding: 45px 50px;
  @media screen and (max-width: 991.5px) {
    width: 100%;
  }
}
.contacts-item {
  display: flex;
  align-items: flex-start;
  padding-left: 50px;
  position: relative;
  margin-bottom: 20px;
}
.contacts-item__img {
  position: absolute;
  left: 0;
}
.contacts-item__img1 {
  left: 5px;
}
.contacts-item__content strong {
  display: block;
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 15px;
  color: #222222;
}
.contacts-item__content span,
.contacts-item__content a {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #222222;
}
.contacts-item__content a:hover {
  text-decoration: underline;
}
.contacts__btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 991.5px) {
    flex-wrap: wrap;
  }
}
.download-btn {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #222222;
  padding-left: 1rem;
  @media screen and (max-width: 991.5px) {
    margin-top: 1rem;
  }
}
.download-btn:hover {
  color: #e86338;
}
.download-btn i {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background: url(../img/icons/download.png) no-repeat center;
}