.service{
  .specialization-screen{
    &__items{
      margin-bottom: 1rem 0 3rem;
    }
    .specialization-desc{
      h3{
        color: $dark-orange-color;
        text-align: left;
        font-weight: 500;
        font-size: 1.75rem;
        position: relative;
        padding-bottom: 1rem;
        margin: 3rem 0 1rem;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 64px;
          height: 4px;
          background: #e86338;
        }
      }
      ul, li, p{
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
      }
      small{
        margin-bottom: 1rem;
      }
      &__add{
        background: linear-gradient(90deg, #0e0400d9, #0e0400d9), url('../img/bg/banner-bg.jpg') no-repeat;
        background-size: cover;
        color: white;
        padding: 2rem 3rem;
        &-text{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          a{
            color: $main-orange-color;
            text-decoration: none;
            transition: all .3s ease-in-out;
            font-weight: 500;
            .link-icon{
              transition: all .3s ease-in-out;
              color: $main-orange-color;
            }
            &:hover{
              text-decoration: none;
              color: white;
              .link-icon{
                transform: translateX(4px);
                color: white;
              }
            }
          }
        }
        &-form .input-tel{
          filter: brightness(10);
        }
      }
      .rate{
        &-img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &-divider{
          margin: 20px 0;
          height: 1px;
          background: #e7e7e7;
        }
        &-title{
          margin: 0 0 10px;
          font-weight: 500;
          font-size: 20px;
          color: #222;
        }
        &-text{
          font-family: "Open Sans",sans-serif;
          font-size: 14px;
          color: #222;
          margin: 0 0 15px;
          line-height: 1.5;
        }
        &-link{
          color: $main-orange-color;
          text-decoration: none;
          transition: all .3s ease-in-out;
          font-weight: 500;
          .link-icon {
            transition: all .3s ease-in-out;
          }
          &:hover{
            text-decoration: none;
            color: $main-orange-color;
            .link-icon {
              transition: all .3s ease-in-out;
              transform: translateX(4px);
            }
          }
        }
        &-content{
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
      }
    }
  }
  .about-screen{
    padding: 3rem 0 9rem;
  }
  .comments-screen{
    padding: 3rem 0;
  }
  .question-screen{
    textarea.comments-screen__input{
      height: calc(100% - 2.5rem);
      padding: 1rem;
    }
  }
}
.question-screen{
  &__title{
    color: $dark-orange-color;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &__subtitle{
    color: #1c1c1c;
    text-align: left;
    font-weight: 500;
    font-size: 1.75rem;
    position: relative;
    padding-bottom: 1rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 64px;
      height: 4px;
      background: #e86338;
    }
  }
  &__accordion{
    margin-bottom: 3rem;
    .accordion-button{
      &:not(.collapsed){
        background: $secondary-orange;
        color: #e86338;
        &::after{
          filter: hue-rotate(90deg);
        }
      }
      &:focus{
        box-shadow: $focus;
      }
    }
  }
  &__form{
    background: #f8f8f8;
    border-radius: 4px;
    padding: 2rem;
  }
}