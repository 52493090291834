.article-page{
  .specialization-screen__title{
    color: #1c1c1c;
    text-align: center;
    font-weight: 500;
    font-size: 2.25rem;
    position: relative;
    padding-bottom: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: "";
      width: 64px;
      height: 4px;
      background: #e86338;
    }
  }
  .item-container{
    background: white;
  }
  .item-card{
    margin: 0 0 3rem 0;
  }
}