.articles-screen{
  background: $gray-bg-color;
  padding-bottom: 3rem;
  &__title{
    color: $dark-orange-color;
    text-align: center;
    font-weight: 500;
    font-size: $h2-font-size;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: '';
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &__card-container{
    height: 100%;
    padding-bottom: 1rem;
    &>.articles-screen__card{
      height: 100%;
    }
  }
  &__card{
    background: white;
    position: relative;
    border-radius: $border-radius-btn;
    box-shadow: $card-shadow;
    transition: all .3s ease-in-out;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &::before{
      position: absolute;
      content: "";
      border: 1px solid $gray-bg-color;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      margin: 1rem;
      border-radius: $border-radius-btn;
      z-index: 0;
    }
    &-desc{
      padding: 2rem;
    }
    &-label{
      font-size: .875rem;
      color: $main-orange-color;
    }
    &-title{
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: .5rem;
      color: $dark-orange-color;
    }
    &-time{
      color: $secondary-gray;
      font-size: .875rem;
    }
    &-btn{
      display: flex;
      justify-content: center;
      padding: 0 2rem 2rem 2rem;
    }
    &-link{
      display: block;
      text-decoration: none;
      color: $main-orange-color;
      background: white;
      border: 1px $main-orange-color solid;
      transition: all .3s ease-in-out;
      padding: .5rem 2rem;
      border-radius: $border-radius-btn;
      position: relative;
      z-index: 1;
      &:hover{
        color: white;
        cursor: pointer;
        background: $main-orange-color;
        border: 1px $main-orange-color solid;
      }
    }
    .orange-btn{
      z-index: 1;
      position: relative;
    }
    &-adv{
      font-size: 1.8rem;
      text-align: center;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: .5rem;
      color: $dark-orange-color;
    }
    &:hover{
      box-shadow: $card-shadow__hover;
      transform: translateY(-3px);
    }
    &__pagination{

    }
  }
}