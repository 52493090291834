.orange-btn{
  font-size: $btn-font-size;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  background: $orange-gradient;
  border: none;
  border-bottom: 3px solid #cb471c;
  border-radius: $border-radius-btn;
  line-height: 1;
  white-space: nowrap;
  padding: 18px 25px 15px 25px;
  cursor: pointer;
  text-decoration: none;
  &:hover{
    background: $orange-gradient-active;
    color: white;
  }
}