.breadcrumb{
  margin-bottom: 0;
  background: #f8f8f8;
  &__container{
    text-align: center;
    padding: 1rem 0;
  }
  &__section{
    color: $secondary-gray;
    text-decoration: none;
    transition: all .3s ease-in-out;
    &:hover{
      color: $main-orange-color;
    }
  }
  &__section:last-child{
    color: $main-dark-color;
    font-weight: 500;
  }
  &__icon{
    color: $main-orange-color;
  }
}