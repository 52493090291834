.social-screen{
  background: $gray-bg-color;
  position: relative;
  padding: 3rem 0 9rem;
  &__title{
    color: #1c1c1c;
    text-align: center;
    font-weight: 500;
    font-size: 2.25rem;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: "";
      width: 64px;
      height: 4px;
      background: #e86338;
    }
  }
  &__subtitle{
    color: $dark-orange-color;
    text-align: left;
    font-weight: 500;
    font-size: 1.75rem;
    position: relative;
    padding-bottom: 1rem;
    margin: 3rem 0 1rem;
    &::after{
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &__card{
    display: block;
    position: relative;
    background: white;
    border-radius: $border-radius-btn;
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: $card-shadow;
    transition: all .3s ease-in-out;
    text-align: center;
    text-decoration: none;
    color: $dark-orange-color;
    &:hover{
      box-shadow: $card-shadow__hover;
      transform: translateY(-3px);
      color: $dark-orange-color;
      text-decoration: none;
      .social-screen__card-logo{
        color: $main-orange-color;
        border: 2px solid $main-orange-color;
      }
      .social-screen__card-link{
        color: $main-orange-color;
      }
    }
    &-logo{
      font-size: 1.2rem;
      width: 2.5rem;
      height: 2.5rem;
      border: 2px solid $dark-orange-color;
      border-radius: 50%;
      line-height: 2.4rem;
      text-align: center;
      transition: all .3s ease-in-out;
      margin: 0 auto 1rem auto;
    }
    &-link{
      color: $dark-orange-color;
      transition: all .3s ease-in-out;
      font-weight: 500;
    }
  }
  &__map{
    width: 100%;
    min-height: 600px;
  }
  &__attention{
    background: white;
    color: $main-orange-color;
    width: 60%;
    text-align: center;
    margin: auto;
    padding: 1rem;
    border-radius: 3px;
    font-weight: 500;
    border: 1px #dadada solid;
  }
  &:before {
    content: "";
    position: absolute;
    background: url(../img/bg/vector-zz-white.svg) no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
}
.requisites-screen{
  background: white;
  padding: 1rem 0 3rem;
  &__title{
    color: #1c1c1c;
    text-align: center;
    font-weight: 500;
    font-size: 2.25rem;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: "";
      width: 64px;
      height: 4px;
      background: #e86338;
    }
  }
  &__subtitle{
    color: $dark-orange-color;
    text-align: left;
    font-weight: 500;
    font-size: 1.75rem;
    position: relative;
    padding-bottom: 1rem;
    margin: 3rem 0 1rem;
    &::after{
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  li::marker{
    color: $main-orange-color;
  }
}
