.tab {
  display: none;
}
.tab-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
  justify-content: space-between;
}
.tab-radio {
  display: none;
}
.tab-radio:checked + .tab {
  display: block !important;
}
.tabs {
  padding-top: 15px;
}
.tabs-btns {
  display: flex;
  width: 100%;
  max-width: 920px;
  margin: 0 auto 55px auto;
  position: relative;
  @media screen and (max-width: 991.5px){
    flex-direction: column;
  }
}
.tabs-btns::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  border: 2px solid #dcdcdc;
}
.tab-label {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #222222;
  position: relative;
  border-radius: 4px;
  transition: all .3s ease-in-out;
  @media screen and (max-width: 991.5px){
    padding: 1rem 0;
  }
}
.tab-label::before {
  position: absolute;
  height: 100%;
  width: 2px;
  content: '';
  background: #dcdcdc;
  top: 0;
  right: -1px;
}
.tab-label:last-child::before {
  display: none;
}
.tab-label.active {
  color: #ffffff;
  position: relative;
  background: linear-gradient(to bottom, #1c1c1c, #1c1c1c);
  &:hover{
    color: #ffffff;
  }
}
.tab-label:hover {
  z-index: 3;
  color: $main-orange-color;
}
.tab-all-btn{
  width: 100%;
  text-align: center;
  transition: all .3s ease-in-out;
  &:hover{
    color: $main-orange-color;
    transform: translateY(-3px);
    cursor: pointer;
  }
  & :last-child{
    padding-left: .5rem;
  }
}