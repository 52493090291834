.article{
  background: $gray-bg-color;
  &-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0 1rem;
    &__label{
      color: $main-orange-color;
    }
    &__time{
      color: $secondary-gray;
    }
  }
  &-title{
    color: $dark-orange-color;
    text-align: left;
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 64px;
      height: 4px;
      background: $main-orange-color;
    }
  }
  &-nav{
    border-radius: 3px;
    padding: 1rem;
    margin: auto;
    ol {
      list-style: none;
      counter-reset: li;
    }
    &>ol{
      padding: 0;
      margin: 0;
    }
    li a{
      text-decoration: none;
      color: $dark-orange-color;
      transition: all .3s ease-in-out;
      &::before {
        transition: all .3s ease-in-out;
        color: $dark-orange-color;
        counter-increment: li;
        content: counters(li, ".") ". ";
      }
      &:hover{
        color: $main-orange-color;
        &::before {
          color: $main-orange-color;
        }
      }
    }
  }
  &-main{
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  &-table{
    overflow-x: auto;
  }
  &-text{
    padding: 3rem;
    background: white;
    @media screen and (max-width: 991.5px){
      padding: 1rem;
    }
    h1{
      margin: 2rem 0 1rem;
    }
    h2{
      text-align: center;
      margin-bottom: 2rem;
    }
    h3{
      text-align: left;
      margin: 2rem 0 1rem;
    }
    img{
      width: 100%;
      object-fit: cover;
      margin-bottom: 1rem;
      box-shadow: 1px 3px 7px 1px #0000004f;
    }
    p, li, td {
      font-family: "Open Sans", sans-serif;
      line-height: 1.48;
    }
    ol {
      list-style: none;
      counter-reset: li;
    }
    ol>li:before {
      counter-increment: li;
      content: counters(li, ".") ". ";
    }
    th {
      text-transform: none;
    }
    th{
      line-height: 1.48;
    }
    th, td{
      text-align: left;
      line-height: 1.56;
    }
    &__card{
      @extend .articles-screen__card;
      &-title{
        padding: 2rem 2rem 1rem 2rem;
        text-align: center;
        font-size: 1.2rem;
        color: $main-orange-color;
        font-weight: 500;
      }
      &-text{
        padding: 0 2rem 1rem 2rem;
        font-family: "Open Sans", sans-serif;
      }
      &-btn{
        @extend .articles-screen__card-btn;
      }
    }
  }
  &-social{
    padding-top: 3rem;
    .share-block{
      position: sticky;
      top: 10px;
    }
    .ya-share2{
      &__title {
        display: none;
      }
      &__container{
        display: flex;
        justify-content: center;
      }
    }
    @media screen and (max-width: 991.5px){
      background: white;
    }
  }
}