.up-link{
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
  color: #e57c5a;
  font-size: 1.2rem;
  bottom: 20px;
  right: 20px;
  position: fixed;
  box-shadow: $card-shadow;
  text-decoration: none;
  opacity: 0;
  z-index: 10;
  transition: all .3s ease-in-out;
  &:hover{
    color: $main-orange-color;
  }
  &.active{
    opacity: 1;
  }
}