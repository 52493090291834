.pagination{
  justify-content: center;
  padding: 1rem 0 0 0;
  .page-item{
    &.disabled{
      visibility: hidden;
    }
    .page-link{
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      line-height: 2rem;
      padding: 0;
      text-align: center;
      color: $main-dark-color;
      margin: .2rem;
      border-radius: $border-radius-btn;
      &:focus{
        box-shadow: $focus;
      }
      &:hover{
        color: $main-orange-color;
        background: $secondary-orange;
      }
    }
    &:first-child, &:last-child{
      transition: all .3s ease-in-out;
      .page-link{
        background: transparent;
        border: 0;
      }
    }
    &:first-child:hover{
      transform: translateX(-3px);
    }
    &:last-child:hover{
      transform: translateX(3px);
    }
  }
}