.banner-form-block {
  display: block;
  width: 330px;
  padding: 40px 30px 45px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  @media screen and (max-width: 991.5px){
    width: 100%;
  }
}
.banner-form-title {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: #1c1c1c;
  margin-bottom: 15px;
}
.banner-form {
  flex-shrink: 0;
  padding: 0 130px 0 80px;
  position: relative;
}
.banner-form-shape {
  position: absolute;
  top: -30px;
  left: -10px;
  width: 500px;
  height: 500px;
  background: url(../img/bg/banner-shape.png) no-repeat center;
  background-size: contain;
}
.banner-form-subtitle {
  font-family: "Open Sans", sans-serif;
  display: block;
  margin-bottom: 20px;
  font-size: 13px;
  color: #222222;
  text-align: center;
}

