.footer{
  &-list{
    margin: 3rem 0;
    &__header{
      background: #1d1d1d;
      color: white;
      text-align: center;
      font-weight: 500;
    }
    &__items{
      display: flex;
      justify-content: start;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    &__header{
      margin-bottom: .5rem;
    }
    &__main-link:not(.footer-list__items :first-child){
      margin-top: .5rem;
    }
    &__main-link{
      color: $dark-orange-color;
      font-weight: 500;
      font-size: .875rem;
      transition: all .3s ease-in-out;
      text-decoration: none;
      margin-bottom: .1rem;
      line-height: 1.48;
      &:hover{
        color: $main-orange-color;
      }
    }
    &__main-link:not(a){
      color: black;
      &:hover{
        color: black;
      }
    }
    &__link{
      color: black;
      font-size: .875rem;
      transition: all .3s ease-in-out;
      text-decoration: none;
      line-height: 1.48;
      &:hover{
        color: $main-orange-color;
      }
    }
  }
  &-desc{
    padding: 1rem 0;
    border-top: 1px #dcdcde solid;
    &__img{
      width: 100%;
      object-fit: cover;
    }
    &__copy{
      font-size: .875rem;
      font-family: "Open Sans",sans-serif;
    }
  }
}