.time-line{
  padding: 1rem 0 0;
  &__item{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-text{
      font-size: .75rem;
      margin: 0 .5rem .5rem .5rem;
      background: #fff;
      padding: .5rem;
      border-radius: $border-radius-btn;
      box-shadow: 2px 5px 4px rgba(50, 50, 93, .1), 0 5px 14px rgba(0, 0, 0, .1);
      font-family: "Open Sans", sans-serif;
    }
    &-dot{
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background: $main-orange-color;
      border-radius: 50%;
      position: relative;
      &:after{
        content: "";
        display: block;
        width: 500px;
        height: 4px;
        position: absolute;
        background: $main-orange-color;
      }
      &:before{
        content: "";
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin: calc((2rem - 1.5rem) / 2);
        position: absolute;
        background: #fff;
        border-radius: 50%;
        z-index: 1;
      }
    }
    &-comment{
      font-weight: 600;
      font-size: 1.125rem;
      margin-bottom: .5rem;
      text-align: center;
    }
  }
  &__carousel{
    .owl-item:nth-child(odd){
      .time-line__item{
        flex-direction: column-reverse;
        bottom: 200px;
        height: 480px;
        &-dot{
          &:after{
            left: 0;
            bottom: 14px;
          }
        }
      }
    }
    .owl-item:nth-child(even){
      .time-line__item{
        top: 247px;
        height: 300px;
        &-dot{
          &:after{
            left: 0;
            top: 15px;
          }
        }
      }
    }
    .owl-nav{
      .owl-next{
        position: absolute;
        top: 224px;
        right: 0;
        font-size: 3rem;
        color: $main-orange-color;
        background: transparent;
        &.disabled{
          color: #dbdbdb;
          opacity: 1;
          &:hover{
            cursor: default;
            color: #dbdbdb;
          }
        }
        &:hover{
          color: $main-orange-color;
          background: transparent;
        }
      }
      .owl-prev{
        display: none;
      }
    }
    .owl-dots{
      display: none;
    }
  }
}