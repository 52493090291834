.item {
  width: 33.333%;
  padding: 0 15px 30px;
  @media screen and (min-width: 767.5px) and (max-width: 991.5px){
    width: 50%;
  }
  @media screen and (max-width: 767.5px){
    width: 100%;
  }
}
.item-container {
  height: 100%;
  box-shadow: 0 0 15px 1px #c5c5c5;
  border-radius: 4px;
  overflow: hidden;
  background: white;
}
.item-img {
  height: 200px;
  overflow: hidden;
}
.item-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item-content {
  padding: 20px 30px 30px;
}
.item-title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  color: #222222;
  line-height: 1.1;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}
.item-list {
  padding: 0;
  margin: 15px 0;
  list-style: none;
}
.item-list li {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #222222;
  line-height: 1.4;
  padding-left: 17px;
}
.item-list li::before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  background: #999999;
  border-radius: 100%;
  left: 0;
  top: 8px;
}
.item-list li:nth-child(n+4) {
  display: none;
}
.active .item-list li:nth-child(n) {
  display: block;
}
.item-link-more {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  color: #e86338;
  cursor: pointer;
  position: relative;
  text-decoration: underline;
}
.item-link-more:hover {
  text-decoration: none;
}
.item-link-more::after {
  position: relative;
  top: -3px;
  content: '';
  display: inline-block;
  margin-left: 10px;
  width: 4px;
  height: 4px;
  border-right: 1px solid #e86338;
  border-bottom: 1px solid #e86338;
  transform: rotate(45deg);
}
.active .item-link-more::after {
  transform: rotate(225deg);
  top: -1px;
}
.item-btn {
  margin: 15px auto 20px;
  display: none;
  width: 100%;
  max-width: 190px;
  text-align: center;
}
.active .item-btn {
  display: block;
}
.item-link-more-active {
  display: none;
}
.active .item-link-more-active {
  display: inline-block;
}
.active .item-link-more-text {
  display: none;
}


.objects {
  margin-top: -60px;
  padding: 300px 0;
  background: url(../images/objects-bg.png) no-repeat center;
  background-size: auto 100%;
}
.objects-slider {
  margin-top: 40px;
  padding: 0 95px;
  @media screen and (max-width: 991.5px){
    padding: 0;
  }
}
.object-container {
  width: 100%;
  border-radius: 7px;
  background: #ffffff;
  display: flex;
  padding: 15px 30px 15px 15px;
  @media screen and (max-width: 991.5px){
    flex-wrap: wrap;
    padding: 1rem;
  }
}
.object-images {
  width: 380px;
  margin-right: 35px;
  flex-shrink: 0;
  @media screen and (max-width: 991.5px){
    width: 100%;
  }
}
.object-content {
  padding: 15px 0;
}
.object-title {
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 20px;
  color: #222222;
}
.object-time {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #222222;
}
.object-divider {
  margin: 20px 0;
  height: 1px;
  background: #e7e7e7;
}
.object-text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #222222;
  margin: 0 0 15px;
  line-height: 1.5;
}
.object-b {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #222222;
}
.object-nums {
  display: block;
  font-weight: 500;
  font-size: 21px;
  color: #e86338;
}
.object-nums sup {
  font-size: 14px;
}
.object-btn {
  display: block;
  width: 250px;
  text-align: center;
  margin-top: 30px;
  @media screen and (max-width: 991.5px){
    margin: auto;
  }
}
.object-images-for {
  height: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}
.object-images-for img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.object-images-nav {
  display: flex;
  margin: 0 -5px;
}
.object-images-nav-item {
  width: 33.333%;
  height: 80px;
  padding: 0 5px;
}
.object-images-nav-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.comments-screen__card{
  display: flex;
  flex-direction: row;
  box-shadow: $unactive-card;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0 3rem;
  @media screen and (max-width: 767.5px){
    flex-direction: column;
    & :first-child{
      order: 1;
    }
    & :last-child{
      order: 0;
    }
  }
  &-info{
    padding: 1rem;
  }
  &-img{
    object-fit: cover;
    width: 100%;
  }
  &-name{
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
    color: $main-orange-color;
  }
  &-text p{
    font-family: "Open Sans", sans-serif;
    line-height: 1.43;
  }
  &-from{
    font-style: italic;
    text-align: right;
    font-family: "Open Sans", sans-serif;
    color: $main-orange-color;
  }
}

