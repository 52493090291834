.headroom {
  background: white;
  width: 100%;
  transition: all .5s ease-in-out;
  box-shadow: 0 0 15px 1px #0000004f;
  .main-header_info{
    padding: 10px 0;
  }
}
.headroom--top {
  display: none;
}
.headroom--pinned.headroom--not-top {
  display: block;
  transform: translateY(-100%);
  position: fixed;
  z-index: 5;
  @media screen and (min-width: 575.5px) and (max-width: 991.5px){
    transform: translateY(-126%);
  }
  @media screen and (max-width: 575.5px){
    transform: translateY(-119%);
  }
}
.headroom--unpinned {
  transform: translateY(-218%);
}