.staff-screen{
  padding: 1rem 0 9rem;
  position: relative;
  .slick-list{
    margin: 0 3rem;
    padding: 1rem 0 2rem;
  }
  &::before{
    content: "";
    position: absolute;
    background: url(../img/bg/vector-zz-gray.svg) no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__item{
    margin: 3rem .5rem 1rem .5rem;
    height: calc(100% - 3rem);
    background: white;
    border-radius: $border-radius-btn;
    box-shadow: 0 2px 4px 1px rgba(0,0,0,.15);
    padding: 2.5rem 0 0 0;
    display: flex;
    flex-flow: wrap column;
    align-items: center;
    position: relative;
  }
  &__none-img{
    @extend .staff-screen__img;
    line-height: 100px;
    text-align: center;
    font-size: 2rem;
    color: white;
    background: linear-gradient(87deg, #ff8467 0, #ef6b40 100%);
  }
  &__img{
    position: absolute;
    display: block;
    width:100px;
    height:100px;
    border-radius:50%;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 0;
    border:2px solid #fff;
    box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
  }
  &__name{
    padding: 2rem 1rem 1rem;
    text-align: center;
    color:$dark-orange-color;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
  &__position{
    padding: 1rem 0;
    background: $secondary-orange;
    color: $main-orange-color;
    font-size:.875rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    text-align: center;
  }
  &__indexes{
    background: #f7f9fc;
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    font-size: .875rem;
    border-bottom-left-radius: $border-radius-btn;
    border-bottom-right-radius: $border-radius-btn;
  }
}