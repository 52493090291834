table {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  border-radius: 10px;
  border-spacing: 0;
  text-align: center;
}
table, tbody, tr {
  width: 100%;
}
td, th {
  width: 25%;
}
th {
  background: $secondary-orange;
  color: $main-orange-color;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  font-weight: 500;
  font-size: 1rem;
}
th, td {
  border-style: solid;
  //border-width: 1px;
  //border-color: #c1c1c1;
  padding: 1rem 1.5rem;
}
tr td:nth-child(even){
  font-weight: 500;
}
tr:nth-child(odd){
  background: #e1e1e1;
}
tr:nth-child(even){
  background: #fff;
}