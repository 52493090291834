.about{
  .about-screen{
    background: $gray-bg-color;
    padding-bottom: 5rem;
    &::before{
      content: "";
      position: absolute;
      background: url(../img/bg/vector-zz-white.svg) no-repeat center bottom;
      width: 100%;
      height: 100%;
      background-size: 100%;
      bottom: -1px;
      left: 0;
    }
  }
  .comments-screen{
    padding: 3rem 0;
  }
}
.staff-screen{
  &__title{
    color: #1c1c1c;
    text-align: center;
    font-weight: 500;
    font-size: 2.25rem;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    &::after{
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: "";
      width: 64px;
      height: 4px;
      background: #e86338;
    }
  }
}
.vacancies-screen{
  padding: 1rem 0 9rem;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    background: url(../img/bg/vector-zz-gray.svg) no-repeat center bottom;
    width: 100%;
    height: 100%;
    background-size: 100%;
    bottom: -1px;
    left: 0;
  }
  &__card{
    box-shadow: $unactive-card;
    margin: 1rem;
    border-radius: 3px;
    padding: 3rem;
    &-money{
      font-size: 1.5rem;
      color: $main-orange-color;
      margin-bottom: 3rem;
    }
    li::marker{
      color: $main-orange-color;
    }
    @media screen and (max-width: 991.5px){
      padding: 3rem 1rem;
      margin: 0;
    }
  }
  &__title{
    color: #1c1c1c;
    text-align: center;
    font-weight: 500;
    font-size: 2.25rem;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    &::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 32px);
      content: "";
      width: 64px;
      height: 4px;
      background: #e86338;
    }
  }
}