.modal-dialog{
  justify-content: center;
}
.modal-content{
  border: none;
  width: fit-content;
  .main-screen__form{
    display: flex;
    justify-content: center;
  }
  .main-screen__privacy{
    padding: 2rem;
    &-header{
      color: $main-dark-color;
      text-align: left;
      font-weight: 500;
      font-size: 1.75rem;
      position: relative;
      padding-bottom: 1rem;
    }
    &-list{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 1rem;
    }
    &-li{
      @media screen and (min-width: 992px) {
        padding: 0 0 0 1rem;
      }
    }
  }
}